export class Colors {
  static bluePrimary: string = '#2CBDF0';
  static blueSecondary: string = '#002366';
  static blueOrder: string = '#0a6986';
  static lightGreyPrimary: string = '#E6EAED';
  static lightGreySecondary: string = '#BABEC2';
  static greyPrimary: string = '#393C43';
  static greySecondary: string = '#4C5055';
  static greyHeadline: string = '#4b4f59';
  static greenCustomer: string = '#1FD21B';
  static mintGreen: string = '#B7EB8F';
  static redPrimary: string = '#F13433';
}
