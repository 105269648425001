import { Router } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { Injectable } from '@angular/core';
import { Case } from 'src/app/modules/cases/models/case/case.model';
import { TranslateService } from '@ngx-translate/core';
import { Colors } from 'src/assets/styles/colors';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  countries: SelectItem[] = [];
  specialistFields: SelectItem[] = [];
  orderStatusColors: { statusColor: string; statusBackgroundColor: string } = {
    statusColor: '',
    statusBackgroundColor: '',
  };

  constructor(
    private translateService: TranslateService,
    private router: Router
  ) {}

  /**
   *
   * @param param string to translate
   * @returns the traslation string
   */
  translate(param: string): string {
    return this.translateService.instant(param);
  }

  /**
   * relode and refresh just the current active route
   */
  refreshComponent() {
    const currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  /**
   * reload the whole page
   */
  reloadWindow() {
    window.location.reload();
  }

  /**
   * get the roder and return the suitable status colore
   * @param order from Tape Case
   * @returns a json Object habe two properties statusColor and statusBackgroundColor.
   */
  getCaseStatusColors(order: Case) {
    switch (order.status?.toLowerCase()) {
      case 'orderinprogress': {
        return {
          statusColor: '#ffffff',
          statusBackgroundColor: Colors.blueSecondary,
        };
      }

      case 'delivered': {
        return {
          statusColor: '#168500',
          statusBackgroundColor: '#61d466',
        };
      }

      case 'assignment': {
        return {
          statusColor: '#6d3e00',
          statusBackgroundColor: '#ad8661',
        };
      }

      case 'offer': {
        return {
          statusColor: '#001b66',
          statusBackgroundColor: '#636aca',
        };
      }
      // case 'orderinprogress': {
      //   this.statusColor = '#1a9696';
      //   this.statusBackgroundColor = '#91fdef';
      //   break;
      // }
      case 'controll': {
        return {
          statusColor: '#7c7e04',
          statusBackgroundColor: '#eeedb1',
        };
      }

      case 'offercorrection': {
        return {
          statusColor: '#480072',
          statusBackgroundColor: '#a63bee',
        };
      }

      case 'processingcorrection': {
        return {
          statusColor: '#730077',
          statusBackgroundColor: '#d79bff',
        };
      }

      case 'controllcorrection': {
        return {
          statusColor: '#730077',
          statusBackgroundColor: '#d79bff',
        };
      }

      case 'orderrejected': {
        return {
          statusColor: '#800000',
          statusBackgroundColor: '#faafaf',
        };
      }

      case 'acceptanceorder': {
        return {
          statusColor: '#024b0e',
          statusBackgroundColor: '#07dd2b',
        };
      }

      case 'ordercompleted': {
        return {
          statusColor: '#000000',
          statusBackgroundColor: Colors.mintGreen,
        };
      }

      case 'canceled': {
        return {
          statusColor: '#dddddd',
          statusBackgroundColor: '#e00000',
        };
      }
    }
    return {
      statusColor: '#ffffff',
      statusBackgroundColor: '#10b401',
    };
  }

  /**
   *
   * @returns the specialistFields array as a SelectItem[] object.
   */
  getSpecialistField(): SelectItem[] {
    return (this.specialistFields = [
      { label: this.translate('SPECIALIST_FIELDS.NO_SPECIFICATION'), value: 0 },
      {
        label: this.translate(
          'SPECIALIST_FIELDS.GENERAL_CONVERSATION_GREETINGS_LETTERS'
        ),
        value: 1,
      },
      {
        label: this.translate('SPECIALIST_FIELDS.AUTOMATION_AND_ROBOTICS'),
        value: 2,
      },
      {
        label: this.translate(
          'SPECIALIST_FIELDS.CONSTRUCTION_CIVIL_ENGINEERING'
        ),
        value: 3,
      },
      {
        label: this.translate('SPECIALIST_FIELDS.MINING_AND_MINERALS_GEMS'),
        value: 4,
      },
      { label: this.translate('SPECIALIST_FIELDS.EDUCATION'), value: 5 },
      {
        label: this.translate('SPECIALIST_FIELDS.BIOLOGY_GENETICS_BOTANY'),
        value: 6,
      },
      { label: this.translate('SPECIALIST_FIELDS.CHEMISTRY'), value: 7 },
      {
        label: this.translate('SPECIALIST_FIELDS.COMPUTERS_HARDWARE'),
        value: 8,
      },
      {
        label: this.translate('SPECIALIST_FIELDS.COMPUTERS_SOFTWARE'),
        value: 9,
      },
      { label: this.translate('SPECIALIST_FIELDS.ELECTRONICS'), value: 10 },
      {
        label: this.translate(
          'SPECIALIST_FIELDS.ELECTRICAL_ENERGY_POWER_GENERATION'
        ),
        value: 11,
      },
      { label: this.translate('SPECIALIST_FIELDS.NUTRITION'), value: 12 },
      { label: this.translate('SPECIALIST_FIELDS.CINEMA_FILM_TV'), value: 13 },
      { label: this.translate('SPECIALIST_FIELDS.FINANCE_GENERAL'), value: 14 },
      { label: this.translate('SPECIALIST_FIELDS.HISTORY'), value: 15 },
      {
        label: this.translate('SPECIALIST_FIELDS.IT_INFORMATION_TECHNOLOGY'),
        value: 16,
      },
      { label: this.translate('SPECIALIST_FIELDS.REAL_ESTATE'), value: 17 },
      {
        label: this.translate('SPECIALIST_FIELDS.ENGINEERING_INDUSTRIAL'),
        value: 18,
      },
      {
        label: this.translate('SPECIALIST_FIELDS.Investment_Securities'),
        value: 19,
      },
      {
        label: this.translate('SPECIALIST_FIELDS.COSMETICS_BEAUTY_ECONOMICS'),
        value: 20,
      },
      {
        label: this.translate('SPECIALIST_FIELDS.AUTOMOTIVE_CARS_AND_TRUCKS'),
        value: 21,
      },
      {
        label: this.translate('SPECIALIST_FIELDS.ART_ARTS_CRAFTS_PAINTING'),
        value: 22,
      },
      {
        label: this.translate('SPECIALIST_FIELDS.MARKETING_MARKET_RESEARCH'),
        value: 23,
      },
      {
        label: this.translate(
          'SPECIALIST_FIELDS.MECHANICS_MECHANICAL_ENGINEERING'
        ),
        value: 24,
      },
      {
        label: this.translate(
          'SPECIALIST_FIELDS.MATERIALS_PLASTICS_CERAMICS_ETC'
        ),
        value: 25,
      },
      { label: this.translate('SPECIALIST_FIELDS.MEDICAL'), value: 26 },
      { label: this.translate('SPECIALIST_FIELDS.METROLOGY'), value: 27 },
      {
        label: this.translate('SPECIALIST_FIELDS.FURNITURE_HOUSEHOLD'),
        value: 28,
      },
      { label: this.translate('SPECIALIST_FIELDS.PATENTS'), value: 29 },
      { label: this.translate('SPECIALIST_FIELDS.MANUFACTURING'), value: 30 },
      {
        label: this.translate('SPECIALIST_FIELDS.PUBLIC_RELATIONS'),
        value: 31,
      },
      { label: this.translate('SPECIALIST_FIELDS.ACCOUNTING'), value: 32 },
      { label: this.translate('SPECIALIST_FIELDS.LAW_GENERAL'), value: 33 },
      {
        label: this.translate(
          'SPECIALIST_FIELDS.LAW_PATENTS_TRADEMARKS_COPYRIGHT'
        ),
        value: 34,
      },
      {
        label: this.translate('SPECIALIST_FIELDS.LAW_TAXATION_AND_CUSTOMS'),
        value: 35,
      },
      { label: this.translate('SPECIALIST_FIELDS.LAW_CONTRACT_S'), value: 36 },
      {
        label: this.translate('SPECIALIST_FIELDS.SPORTS_FITNESS_RECREATION'),
        value: 37,
      },
      {
        label: this.translate('SPECIALIST_FIELDS.ENGINEERING_GENERAL'),
        value: 38,
      },
      {
        label: this.translate('SPECIALIST_FIELDS.TEXTILES_CLOTHING_FASHION'),
        value: 39,
      },
      { label: this.translate('SPECIALIST_FIELDS.TOURISM_TRAVEL'), value: 40 },
      {
        label: this.translate(
          'SPECIALIST_FIELDS.TRANSPORT_TRANSPORTATION_SHIPPING'
        ),
        value: 41,
      },
      {
        label: this.translate('SPECIALIST_FIELDS.ENVIRONMENT_AND_ECOLOGY'),
        value: 42,
      },
      {
        label: this.translate(
          'SPECIALIST_FIELDS.Certificates_Diplomas_Licenses_CVs'
        ),
        value: 43,
      },
      { label: this.translate('SPECIALIST_FIELDS.INSURANCE'), value: 44 },
      { label: this.translate('SPECIALIST_FIELDS.ADVERTISING'), value: 45 },
      {
        label: this.translate('SPECIALIST_FIELDS.BUSINESS_COMMERCE_GENERAL'),
        value: 46,
      },
      { label: this.translate('SPECIALIST_FIELDS.SCIENCE_GENERAL'), value: 47 },
      { label: this.translate('SPECIALIST_FIELDS.CHEMISTRY'), value: 48 },
      { label: this.translate('SPECIALIST_FIELDS.GASTRONOMY'), value: 49 },
      { label: this.translate('SPECIALIST_FIELDS.PRESS_RELEASE'), value: 50 },
      { label: this.translate('SPECIALIST_FIELDS.INTRANET'), value: 51 },
      { label: this.translate('SPECIALIST_FIELDS.INTERNET'), value: 52 },
      {
        label: this.translate('SPECIALIST_FIELDS.PERSONNEL_ANNOUNCEMENT'),
        value: 53,
      },
      { label: this.translate('SPECIALIST_FIELDS.OTHER'), value: 54 },
    ]);
  }

  /**
   * give back all of they countries with code in SelectItem innterface Type
   * @returns countries list array
   */
  getCountriesList(): SelectItem[] {
    return (this.countries = [
      { label: 'COUNTRY.UNITED_STATES', value: 1 },
      { label: 'COUNTRY.RUSSIAN_FEDERATION', value: 7 },
      { label: 'COUNTRY.EGYPT', value: 20 },
      { label: 'COUNTRY.SOUTH_AFRICA', value: 27 },
      { label: 'COUNTRY.GREECE', value: 30 },
      { label: 'COUNTRY.NETHERLANDS', value: 31 },
      { label: 'COUNTRY.BELGIUM', value: 32 },
      { label: 'COUNTRY.FRANCE', value: 33 },
      { label: 'COUNTRY.SPAIN', value: 34 },
      { label: 'COUNTRY.HUNGARY', value: 36 },
      { label: 'COUNTRY.ITALY', value: 39 },
      { label: 'COUNTRY.ROMANIA', value: 40 },
      { label: 'COUNTRY.SWITZERLAND', value: 41 },
      { label: 'COUNTRY.AUSTRIA', value: 43 },
      { label: 'COUNTRY.UNITED_KINGDOM', value: 44 },
      { label: 'COUNTRY.DENMARK', value: 45 },
      { label: 'COUNTRY.SWEDEN', value: 46 },
      { label: 'COUNTRY.NORWAY', value: 47 },
      { label: 'COUNTRY.POLAND', value: 48 },
      { label: 'COUNTRY.GERMANY', value: 49 },
      { label: 'COUNTRY.PERU', value: 51 },
      { label: 'COUNTRY.MEXICO', value: 52 },
      { label: 'COUNTRY.CUBA', value: 53 },
      { label: 'COUNTRY.ARGENTINA', value: 54 },
      { label: 'COUNTRY.BRAZIL', value: 55 },
      { label: 'COUNTRY.CHILE', value: 56 },
      { label: 'COUNTRY.COLOMBIA', value: 57 },
      { label: 'COUNTRY.VENEZUELA', value: 58 },
      { label: 'COUNTRY.MALAYSIA', value: 60 },
      { label: 'COUNTRY.AUSTRALIA', value: 61 },
      { label: 'COUNTRY.INDONESIA', value: 62 },
      { label: 'COUNTRY.PHILIPPINES', value: 63 },
      { label: 'COUNTRY.NEW_ZEALAND', value: 64 },
      { label: 'COUNTRY.SINGAPORE', value: 65 },
      { label: 'COUNTRY.THAILAND', value: 66 },
      { label: 'COUNTRY.JAPAN', value: 81 },
      { label: 'COUNTRY.SOUTH_KOREA', value: 82 },
      { label: 'COUNTRY.VIET_NAM', value: 84 },
      { label: 'COUNTRY.CHINA', value: 86 },
      { label: 'COUNTRY.TURKEY', value: 90 },
      { label: 'COUNTRY.INDIA', value: 91 },
      { label: 'COUNTRY.PAKISTAN', value: 92 },
      { label: 'COUNTRY.AFGHANISTAN', value: 93 },
      { label: 'COUNTRY.SRI_LANKA', value: 94 },
      { label: 'COUNTRY.MYANMAR', value: 95 },
      { label: 'COUNTRY.IRAN', value: 98 },
      { label: 'COUNTRY.MOROCCO', value: 212 },
      { label: 'COUNTRY.ALGERIA', value: 213 },
      { label: 'COUNTRY.TUNISIA', value: 216 },
      { label: 'COUNTRY.LIBYA', value: 218 },
      { label: 'COUNTRY.GAMBIA', value: 220 },
      { label: 'COUNTRY.SENEGAL', value: 221 },
      { label: 'COUNTRY.MAURITANIA', value: 222 },
      { label: 'COUNTRY.MALI_REPUBLIC', value: 223 },
      { label: 'COUNTRY.GUINEA', value: 224 },
      { label: 'COUNTRY.IVORY_COAST', value: 225 },
      { label: 'COUNTRY.BURKINA_FASO', value: 226 },
      { label: 'COUNTRY.NIGER', value: 227 },
      { label: 'COUNTRY.TOGOLESE_REPUBLIC', value: 228 },
      { label: 'COUNTRY.BENIN', value: 229 },
      { label: 'COUNTRY.MAURITIUS', value: 230 },
      { label: 'COUNTRY.LIBERIA', value: 231 },
      { label: 'COUNTRY.SIERRA_LEONE', value: 232 },
      { label: 'COUNTRY.GHANA', value: 233 },
      { label: 'COUNTRY.NIGERIA', value: 234 },
      { label: 'COUNTRY.CHAD', value: 235 },
      { label: 'COUNTRY.CENTRAL_AFRICAN_REPUBLIC', value: 236 },
      { label: 'COUNTRY.CAMEROON', value: 237 },
      { label: 'COUNTRY.CAPE_VERDE_ISLANDS', value: 238 },
      { label: 'COUNTRY.SÃO_TOMÉ_AND_PRINCIPE', value: 239 },
      { label: 'COUNTRY.EQUATORIAL_GUINEA', value: 240 },
      { label: 'COUNTRY.GABONESE_REPUBLIC', value: 241 },
      { label: 'COUNTRY.CONGO', value: 242 },
      { label: 'COUNTRY.CONGO_DEM_REP_OF', value: 243 },
      { label: 'COUNTRY.ANGOLA', value: 244 },
      { label: 'COUNTRY.GUINEA_BISSAU', value: 245 },
      { label: 'COUNTRY.DIEGO_GARCIA', value: 246 },
      { label: 'COUNTRY.ASCENSION', value: 247 },
      { label: 'COUNTRY.SEYCHELLES_REPUBLIC', value: 248 },
      { label: 'COUNTRY.SUDAN', value: 249 },
      { label: 'COUNTRY.RWANDESE_REPUBLIC', value: 250 },
      { label: 'COUNTRY.ETHIOPIA', value: 251 },
      { label: 'COUNTRY.SOMALI_DEMOCRATIC_REPUBLIC', value: 252 },
      { label: 'COUNTRY.DJIBOUTI', value: 253 },
      { label: 'COUNTRY.KENYA', value: 254 },
      { label: 'COUNTRY.ZANZIBAR_ZANZANIA', value: 255 },
      { label: 'COUNTRY.UGANDA', value: 256 },
      { label: 'COUNTRY.BURUNDI', value: 257 },
      { label: 'COUNTRY.MOZAMBIQUE', value: 258 },
      { label: 'COUNTRY.ZAMBIA', value: 260 },
      { label: 'COUNTRY.MADAGASCAR', value: 261 },
      { label: 'COUNTRY.RÉUNION_ISLAND', value: 262 },
      { label: 'COUNTRY.ZIMBABWE', value: 263 },
      { label: 'COUNTRY.NAMIBIA', value: 264 },
      { label: 'COUNTRY.MALAWI', value: 265 },
      { label: 'COUNTRY.LESOTHO', value: 266 },
      { label: 'COUNTRY.BOTSWANA', value: 267 },
      { label: 'COUNTRY.SWAZILAND', value: 268 },
      { label: 'COUNTRY.MAYOTTE', value: 269 },
      { label: 'COUNTRY.ST_HELENA', value: 290 },
      { label: 'COUNTRY.ERITREA', value: 291 },
      { label: 'COUNTRY.ARUBA', value: 297 },
      { label: 'COUNTRY.FAROE_ISLANDS', value: 298 },
      { label: 'COUNTRY.GREENLAND', value: 299 },
      { label: 'COUNTRY.GIBRALTAR', value: 350 },
      { label: 'COUNTRY.PORTUGAL', value: 351 },
      { label: 'COUNTRY.LUXEMBOURG', value: 352 },
      { label: 'COUNTRY.IRELAND', value: 353 },
      { label: 'COUNTRY.ICELAND', value: 354 },
      { label: 'COUNTRY.ALBANIA', value: 355 },
      { label: 'COUNTRY.MALTA', value: 356 },
      { label: 'COUNTRY.CYPRUS', value: 357 },
      { label: 'COUNTRY.FINLAND', value: 358 },
      { label: 'COUNTRY.BULGARIA', value: 359 },
      { label: 'COUNTRY.LITHUANIA', value: 370 },
      { label: 'COUNTRY.LATVIA', value: 371 },
      { label: 'COUNTRY.ESTONIA', value: 372 },
      { label: 'COUNTRY.MOLDOVA', value: 373 },
      { label: 'COUNTRY.ARMENIA', value: 374 },
      { label: 'COUNTRY.BELARUS', value: 375 },
      { label: 'COUNTRY.ANDORRA', value: 376 },
      { label: 'COUNTRY.MONACO', value: 377 },
      { label: 'COUNTRY.SAN_MARINO', value: 378 },
      { label: 'COUNTRY.VATICAN_CITY', value: 379 },
      { label: 'COUNTRY.UKRAINE', value: 380 },
      { label: 'COUNTRY.SERBIA', value: 381 },
      { label: 'COUNTRY.MONTENEGRO', value: 382 },
      { label: 'COUNTRY.CROATIA', value: 385 },
      { label: 'COUNTRY.SLOVENIA', value: 386 },
      { label: 'COUNTRY.BOSNIA_AND_HERZEGOVINA', value: 387 },
      { label: 'COUNTRY.MACEDONIA', value: 389 },
      { label: 'COUNTRY.CZECH_REPUBLIC', value: 420 },
      { label: 'COUNTRY.SLOVAKIA', value: 421 },
      { label: 'COUNTRY.LIECHTENSTEIN', value: 423 },
      { label: 'COUNTRY.FALKLAND_ISLANDS_MALVINAS', value: 500 },
      { label: 'COUNTRY.BELIZE', value: 501 },
      { label: 'COUNTRY.GUATEMALA', value: 502 },
      { label: 'COUNTRY.EL_SALVADOR', value: 503 },
      { label: 'COUNTRY.HONDURAS', value: 504 },
      { label: 'COUNTRY.NICARAGUA', value: 505 },
      { label: 'COUNTRY.COSTA_RICA', value: 506 },
      { label: 'COUNTRY.PANAMA', value: 507 },
      { label: 'COUNTRY.ST_PIERRE_MIQUELON', value: 508 },
      { label: 'COUNTRY.HAITI', value: 509 },
      { label: 'COUNTRY.GUADELOUPE', value: 590 },
      { label: 'COUNTRY.BOLIVIA', value: 591 },
      { label: 'COUNTRY.GUYANA', value: 592 },
      { label: 'COUNTRY.ECUADOR', value: 593 },
      { label: 'COUNTRY.FRENCH_GUIANA', value: 594 },
      { label: 'COUNTRY.PARAGUAY', value: 595 },
      { label: 'COUNTRY.MARTINIQUE', value: 596 },
      { label: 'COUNTRY.SURINAME', value: 597 },
      { label: 'COUNTRY.URUGUAY', value: 598 },
      { label: 'COUNTRY.NETHERLANDS_ANTILLES', value: 599 },
      { label: 'COUNTRY.CHRISTMAS_ISLAND', value: 618 },
      { label: 'COUNTRY.EAST_TIMOR', value: 670 },
      { label: 'COUNTRY.NORFOLK_ISLAND', value: 672 },
      { label: 'COUNTRY.BRUNEI_DARUSSALAM', value: 673 },
      { label: 'COUNTRY.NAURU', value: 674 },
      { label: 'COUNTRY.PAPUA_NEW_GUINEA', value: 675 },
      { label: 'COUNTRY.TONGA_ISLANDS', value: 676 },
      { label: 'COUNTRY.SOLOMON_ISLANDS', value: 677 },
      { label: 'COUNTRY.VANUATU', value: 678 },
      { label: 'COUNTRY.FIJI_ISLANDS', value: 679 },
      { label: 'COUNTRY.PALAU', value: 680 },
      { label: 'COUNTRY.WALLIS_AND_FUTUNA_ISLANDS', value: 681 },
      { label: 'COUNTRY.COOK_ISLANDS', value: 682 },
      { label: 'COUNTRY.NIUE', value: 683 },
      { label: 'COUNTRY.SAMOA', value: 685 },
      { label: 'COUNTRY.KIRIBATI', value: 686 },
      { label: 'COUNTRY.NEW_CALEDONIA', value: 687 },
      { label: 'COUNTRY.TUVALU', value: 688 },
      { label: 'COUNTRY.FRENCH_POLYNESIA', value: 689 },
      { label: 'COUNTRY.TOKELAU', value: 690 },
      { label: 'COUNTRY.MICRONESIA_FEDERAL_STATES_OF', value: 691 },
      { label: 'COUNTRY.MARSHALL_ISLANDS', value: 692 },
      { label: 'COUNTRY.KOREA_NORTH', value: 850 },
      { label: 'COUNTRY.HONG_KONG', value: 852 },
      { label: 'COUNTRY.MACAO', value: 853 },
      { label: 'COUNTRY.CAMBODIA', value: 855 },
      { label: 'COUNTRY.LAOS', value: 856 },
      { label: 'COUNTRY.BANGLADESH', value: 880 },
      { label: 'COUNTRY.TAIWAN', value: 886 },
      { label: 'COUNTRY.MALDIVES', value: 960 },
      { label: 'COUNTRY.LEBANON', value: 961 },
      { label: 'COUNTRY.JORDAN', value: 962 },
      { label: 'COUNTRY.SYRIA', value: 963 },
      { label: 'COUNTRY.IRAQ', value: 964 },
      { label: 'COUNTRY.KUWAIT', value: 965 },
      { label: 'COUNTRY.SAUDI_ARABIA', value: 966 },
      { label: 'COUNTRY.YEMEN', value: 967 },
      { label: 'COUNTRY.OMAN', value: 968 },
      { label: 'COUNTRY.PALESTINIAN_SETTLEMENTS', value: 970 },
      { label: 'COUNTRY.UNITED_ARAB_EMIRATES', value: 971 },
      { label: 'COUNTRY.ISRAEL', value: 972 },
      { label: 'COUNTRY.BAHRAIN', value: 973 },
      { label: 'COUNTRY.QATAR', value: 974 },
      { label: 'COUNTRY.BHUTAN', value: 975 },
      { label: 'COUNTRY.MONGOLIA', value: 976 },
      { label: 'COUNTRY.NEPAL', value: 977 },
      { label: 'COUNTRY.TAJIKISTAN', value: 992 },
      { label: 'COUNTRY.TURKMENISTAN', value: 993 },
      { label: 'COUNTRY.AZERBAIJAN', value: 994 },
      { label: 'COUNTRY.GEORGIA', value: 995 },
      { label: 'COUNTRY.KYRGYZ_REPUBLIC', value: 996 },
      { label: 'COUNTRY.UZBEKISTAN', value: 998 },
      { label: 'COUNTRY.BAHAMAS', value: 1242 },
      { label: 'COUNTRY.BARBADOS', value: 1246 },
      { label: 'COUNTRY.ANGUILLA', value: 1264 },
      { label: 'COUNTRY.ANTIGUA_BARBUDA', value: 1268 },
      { label: 'COUNTRY.BRITISH_VIRGIN_ISLANDS', value: 1284 },
      { label: 'COUNTRY.US_VIRGIN_ISLANDS', value: 1340 },
      { label: 'COUNTRY.CAYMAN_ISLANDS', value: 1345 },
      { label: 'COUNTRY.BERMUDA', value: 1441 },
      { label: 'COUNTRY.GRENADA', value: 1473 },
      { label: 'COUNTRY.TURKS_AND_CAICOS_ISLANDS', value: 1649 },
      { label: 'COUNTRY.MONTSERRAT', value: 1664 },
      { label: 'COUNTRY.GUAM', value: 1671 },
      { label: 'COUNTRY.AMERICAN_SAMOA', value: 1684 },
      { label: 'COUNTRY.ST_LUCIA', value: 1758 },
      { label: 'COUNTRY.DOMINICA', value: 1767 },
      { label: 'COUNTRY.ST_VINCENT_GRENADINES', value: 1784 },
      { label: 'COUNTRY.PUERTO_RICO', value: 1787 },
      { label: 'COUNTRY.DOMINICAN_REPUBLIC', value: 1809 },
      { label: 'COUNTRY.TRINIDAD_TOBAGO', value: 1868 },
      { label: 'COUNTRY.ST_KITTS_NEVIS', value: 1869 },
      { label: 'COUNTRY.JAMAICA', value: 1876 },
      { label: 'COUNTRY.CANADA', value: 10001 },
      { label: 'COUNTRY.KAZAKHSTAN', value: 10007 },
      { label: 'COUNTRY.ESPERANTO', value: 10010 },
    ].sort((a, b) => a.label.localeCompare(b.label)));
  }
}
